interface ColorsI {
  [key: string]: {
    fromColor: string,
    toColor: string,
  }
}

const colors: ColorsI = {
  dev: {
    fromColor: '#003249',
    toColor: '#007ea7',
  },
  sit: {
    fromColor: '#679436',
    toColor: '#a5be00',
  },
  uat: {
    fromColor: '#f79256',
    toColor: '#ffa62b',
  },
  prod: {
    fromColor: '#960028',
    toColor: '#FF0028',
  },
  default: {
    fromColor: '#c9184a',
    toColor: '#ff4d6d',
  }
}

export const getBannerColors = () => {
  const urlEnv = window.location.hostname.split('.')[1];
  let env = null;

  if (urlEnv === undefined)
    env = 'default';
  else if (urlEnv === 'ai')
    env = 'prod'
  else
    env = urlEnv;

  const fromColor = colors[env].fromColor;
  const toColor = colors[env].toColor;

  return { fromColor, toColor };
}