import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes
} from "@backstage/theme";
import { getBannerColors } from "../utility/banners";


const { fromColor, toColor } = getBannerColors();

export const customDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      navigation: {
        ...palettes.dark.navigation,
        indicator: toColor,
      },
    },
  }),
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: [fromColor, toColor], shape: shapes.round }),
    documentation: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    tool: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    service: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    app: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    apis: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
  },
});


export const customLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      navigation: {
        ...palettes.light.navigation,
        indicator: toColor,
      },
    },
  }),
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: [fromColor, toColor], shape: shapes.round }),
    documentation: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    tool: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    service: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: [fromColor, toColor],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    app: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
    apis: genPageTheme({ colors: [fromColor, toColor], shape: shapes.wave }),
  },
});