import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getBannerColors } from '../../utility/banners';

const useStyles = makeStyles({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  svg: {
    width: '60px',
    height: 'auto',
    marginRight: '5px',
  },
  textWrapper: {
    color: '#fff',
  },
  title: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '10px',
  }
});

const LogoFull = () => {
  const styles = useStyles();
  const { toColor } = getBannerColors();

  return (
    <div className={styles.logoWrapper}>
      <svg className={styles.svg} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="997.279px" height="1000px" viewBox="0 0 997.279 1000" enableBackground="new 0 0 997.279 1000" xmlSpace="preserve">
        <g><polygon fill={toColor} points="51.062,614.077 20.55,644.599 61.983,658.251 	" /><polygon fill={toColor} points="66.139,673.559 42.647,707.018 76.429,709.308 	" /><polygon fill={toColor} points="35.535,557.339 4.482,584.572 47.016,600.417 	" /><polygon fill={toColor} points="100.033,711.17 30.4,536.345 0,520.492 46.252,491.201 1.631,456.879 50.836,432.615 
		9.292,395.217 63.52,378.171 26.468,335.955 81.338,323.81 51.5,277.356 106.268,271.783 81.684,220.846 139.284,222.365 
		122.657,171.966 179.077,176.672 167.281,124.962 220.174,140.701 216.139,85.279 268.729,107.681 272.559,54.339 320.114,84.069 
		328.742,29.19 372.354,63.512 388.991,12.245 427.694,51.702 449.445,3.381 483.99,45.694 511.438,0 540.399,46.252 572.765,4.809 
		594.853,54.219 634.857,15.739 650.281,69.319 693.895,36.521 703.41,90.731 749.539,64.602 751.393,120.803 802.128,98.944 
		797.521,156.214 852.423,142.003 837.987,194.928 891.34,189.785 872.531,239.632 929.492,241.833 902.598,289.176 
		956.499,293.308 923.033,336.618 976.405,352.346 940.086,391.814 989.506,415.223 948.949,449.761 995.528,480.484 
		952.229,505.743 995.415,540.51 947.208,563.139 987.213,604.691 935.513,621.198 968.521,664.821 916.268,676.721 946.77,728.116 
		888.822,728.004 912.335,778.186 857.447,776.332 874.389,828.684 821.495,817.113 833.291,871.882 778.838,856.131 
		782.791,908.709 732.262,887.946 731.172,944.903 684.503,915.818 674.324,967.532 630.814,935.289 613.526,986.554 
		572.988,948.186 553.185,996.833 516.561,954.736 489.228,1000 459.601,952.995 426.156,994.641 404.181,942.938 365.693,983.159 
		349.188,929.065 304.588,964.463 297.253,908.608 252,936.93 251.674,881.389 199.41,902.496 206.074,847.605 150.309,861.491 
		165.202,809.556 110.312,816.339 128.567,764.941 72.598,762.211 	" /><path fill={toColor} d="M876.791,918.011c-0.101-27.772,22.423-48.216,48.992-48.321c26.346,0,48.85,20.449,48.85,48.321
		c0.123,27.982-22.403,48.419-48.747,48.419C899.327,966.43,876.791,945.993,876.791,918.011 M925.886,958.352
		c21.863,0,39.13-17.163,39.13-40.341c0-23.066-17.37-40.23-39.232-40.23c-22.097,0-39.373,17.269-39.373,40.23
		C886.41,941.297,903.687,958.352,925.886,958.352 M915.606,945.993h-8.415l-0.112-55.755h21.211
		c13.234,0,19.784,4.706,19.784,15.86c0,9.944-6.234,14.316-14.415,15.292l15.943,24.603h-9.496l-14.752-24.15h-9.748V945.993z
		 M925.66,914.74c7.223,0,13.683-0.665,13.683-9.301c-0.111-6.775-6.46-8.078-12.255-8.078h-11.481v17.379H925.66z" /><polygon fill="#FFFFFF" points="25.268,519.962 72.383,507.278 166.292,646.013 166.935,645.779 177.224,478.956 223.79,466.499 
		276.267,661.865 244.462,670.495 203.997,519.525 203.344,519.741 190.671,684.919 163.236,692.255 69.644,555.803 69.103,555.803 
		109.536,706.691 77.631,715.208 	" /><path fill="#FFFFFF" d="M308.295,443.741l35.76-9.384l126.163,175.357l-35.86,9.609l-31.918-46.77l-76.418,20.434l-4.177,56.732
		l-34.534,9.189L308.295,443.741z M328.203,564.552l58.476-15.737l-51.376-76.191h-0.224l-0.541,0.212L328.203,564.552z" /><polygon fill="#FFFFFF" points="368.771,427.572 523.783,385.803 531.77,415.328 470.868,431.739 515.479,597.461 481.911,606.536 
		437.312,440.794 376.746,457.097 	" /><polygon fill="#FFFFFF" points="495.91,393.364 651.046,351.82 658.911,381.331 598.021,397.622 642.632,563.351 609.052,572.327 
		564.563,406.698 503.998,422.976 	" /><polygon fill="#FFFFFF" points="663.495,348.525 796.678,312.442 804.644,341.975 704.816,368.961 718.609,420.146 
		810.654,395.317 818.09,423.201 726.149,448.009 741.45,505.078 843.011,477.646 851.109,507.271 715.971,543.67 	" /><polygon fill="#FFFFFF" points="813.832,307.971 847.289,299.003 891.878,464.736 989.19,438.607 997.279,468.126 866.197,503.325 
			" /></g></svg>
      <div className={styles.textWrapper}>
        <div className={styles.title}>Developer Portal</div>
        <div className={styles.subtitle}>Software Catalog & Developer Platform</div>
      </div>
    </div>

  );
};

export default LogoFull;
