import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: 'none',
  },
});
const GlooIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.path} xmlns="http://www.w3.org/2000/svg" width="21.659" height="17.708" viewBox="0 0 21.659 17.708">
      <path className={classes.path} d="M7.245,16.636c1.63,0,1.94-1.063,3.017-1.071s1.546,1.071,3.118,1.071c2.035,0,3.5-3.5,4.005-6.428.226.317.445.629.635.921.946,1.454,1.8,1.293,2.278.95s.591-1.432-.355-2.529A10.238,10.238,0,0,0,17.56,7.728c-.25-3.571-3.392-5.94-7.232-5.94a8.291,8.291,0,0,0-3.837.9,3.9,3.9,0,0,0-.845-.472c-.728-.3-.79-.084-.842-.71A1.52,1.52,0,0,0,3.723.119,2.151,2.151,0,0,0,.985,1.293,1.878,1.878,0,0,0,2.31,3.749c1.739.523,1.951-1.294,2.636-.894.342.142.585.393.277.684l-.055.048-.008.006-.011.01A5.952,5.952,0,0,0,3.1,7.729,10.229,10.229,0,0,0,.714,9.549c-.946,1.1-.828,2.186-.355,2.529s1.332.5,2.277-.95c.175-.269.375-.556.583-.847.481,3.58,2.148,6.355,4.026,6.355" transform="translate(0.501 0.573)" fill="none" stroke="#b5b5b5" strokeWidth="1" fillRule="evenodd" />
    </svg>
  );
};

export default GlooIcon;
